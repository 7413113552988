import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { dtoCurrentUser } from '../dto/dtoCurrentUser';
import { dtoLogin } from '../dto/dtoLogin';
import { dtoRegister } from '../dto/dtoRegister';
import { dtoPassword } from '../dto/dtoPassword';
import { CryptoService } from '../tool/crypto.service';

@Injectable({
	providedIn: 'root'
})
export class AuthService {
	private currentUserSubject: BehaviorSubject<dtoCurrentUser>;
	public currentUser: Observable<dtoCurrentUser>;
	private localData: dtoCurrentUser;

	constructor(
		@Inject('apiUrl') private apiUrl,
		private http: HttpClient,
		private cryptoService: CryptoService
	) {
		if (localStorage.getItem('currentUser') != null)
			this.localData = JSON.parse(this.cryptoService.decrypt(localStorage.getItem('currentUser')));

		this.currentUserSubject = new BehaviorSubject<dtoCurrentUser>(this.localData);
		this.currentUser = this.currentUserSubject.asObservable();
	}

	public get currentUserValue(): dtoCurrentUser {
		return this.currentUserSubject.value;
	}

	public login(param: dtoLogin) {
		const headerContent = new HttpHeaders()
			.set('Content-Type', 'application/json')
			.set('Accept', 'application/json');

		return this.http.post<any>(this.apiUrl + '/api/user/Login', param, { headers: headerContent, observe: 'body' })
			.pipe(map(data => {
				if (data.Result.token) {
					if (data.Result.accountApproval === true) {
						var usr = this.cryptoService.encrypt(JSON.stringify(data.Result));
						localStorage.setItem('currentUser', usr);
						this.currentUserSubject.next(data.Result);
					}
					return data.Result;
				} else {
					return "NotUser";
				}
			}));
	}

	public logout(id) {
		const headerContent = new HttpHeaders()
			.set('Content-Type', 'application/json')
			.set('Accept', 'application/json');

		return this.http.post<any>(this.apiUrl + '/api/user/Logout', id, { headers: headerContent, observe: 'body' })
			.pipe(map((data) => {
				localStorage.removeItem('currentUser');
				this.currentUserSubject.next(null);
				return data;
			}));
	}

	public register(param: dtoRegister) {
		const headerContent = new HttpHeaders()
			.set('Content-Type', 'application/json')
			.set('Accept', 'application/json');

		return this.http.post(this.apiUrl + '/api/user/Register', param, { headers: headerContent, observe: 'body' });
	}

	public accountActive(userId: number) {
		return this.http.get(this.apiUrl + "/api/user/Approve/" + userId);
	}

	public forgetPassword(param: dtoPassword) {
		const headerContent = new HttpHeaders()
			.set('Content-Type', 'application/json')
			.set('Accept', 'application/json');

		return this.http.post(this.apiUrl + '/api/user/ForgetPassword', param, { headers: headerContent, observe: 'body' });
	}
}
