import { AuthService } from '../service/auth.service';
import { dtoCurrentUser } from '../dto/dtoCurrentUser';

export abstract class HelperComponent
{
		public user = new dtoCurrentUser();

		constructor
		(
			public authService: AuthService,
		)
		{
			this.authService.currentUser.subscribe(x => this.user = x);
		}
}