import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { environment } from '../environments/environment';
import { JwtInterceptor } from './interceptor/jwt..interceptor';
import { ErrorInterceptor } from './interceptor/error.interceptor';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

//Tools
import { LoaderService } from './tool/http-loader/loader.service';
import { LoaderInterceptor } from './tool/http-loader/loader.interceptor';
import { HttpLoaderComponent } from './tool/http-loader/http-loader.component';
import { Ng2IziToastModule } from 'ng2-izitoast';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';


@NgModule({
	declarations: [
		AppComponent,
		HttpLoaderComponent,
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		FormsModule,
		Ng2IziToastModule,
		BrowserAnimationsModule,
		NgxDatatableModule.forRoot({
			messages: {
			  emptyMessage: 'Kayıt bulunamadı', // Message to show when array is presented, but contains no values
			  totalMessage: 'Toplam', // Footer total message
			  selectedMessage: 'Seçiniz' // Footer selected message
			}
		  })
	],
	providers: [
		{ provide: 'apiUrl', useValue: environment.apiUrl },
		{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
		LoaderService,
		{ provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
