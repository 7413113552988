import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
	providedIn: 'root'
})
export class CryptoService {

	secretKey = "scrtkyolsn";
	constructor() { }

	encrypt(value: string): string {
		return CryptoJS.AES.encrypt(value, this.secretKey.trim()).toString();
	}

	decrypt(textToDecrypt: string) {
		let data = CryptoJS.AES.decrypt(textToDecrypt, this.secretKey.trim()).toString(CryptoJS.enc.Utf8);
		return data;
	}
}


/*
import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
	providedIn: 'root'
})
export class CryptoService {

	secretKey = "scrtkyolsn";
	constructor() { }

	encrypt(value: string): string {
		return CryptoJS.AES.encrypt(JSON.stringify({ value }), this.secretKey.trim()).toString();
	}

	decrypt(textToDecrypt: string) {
		let data = CryptoJS.AES.decrypt(textToDecrypt, this.secretKey.trim()).toString(CryptoJS.enc.Utf8);
		return data;
	}
}
*/