import { Component, OnInit } from '@angular/core';
import { dtoCurrentUser } from './dto/dtoCurrentUser';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
	currentUser: dtoCurrentUser;

	constructor() {
	}

	ngOnInit(): void {
	
	}

}