import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guard/auth.guard';

const routes: Routes = [
	{ path: 'us', loadChildren: './default-template/default.module#DefaultModule', canActivate: [AuthGuard] },
	{ path: '', loadChildren: './ui-template/default-ui.module#DefaultUiModule' },

	//{ path: 'us', loadChildren: () => import('./default-template/default.module').then(m => m.DefaultModule), canActivate: [AuthGuard] },
	//{ path: '', loadChildren: () => import('./ui-template/default-ui.module').then(m => m.DefaultUiModule) },
	{ path: '**', redirectTo: '' }
];

@NgModule({
	imports: [RouterModule.forRoot(routes,{ enableTracing: false, useHash: true })],
	exports: [RouterModule]
})
export class AppRoutingModule { }
